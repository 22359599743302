import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ExternalLinkIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    fontSize={props.fontSize || 'inherit'}
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3832 3.07605C20.2652 3.02705 20.1357 3 20 3H15L14.8834 3.00673C14.386 3.06449 14 3.48716 14 4L14.0067 4.11662C14.0645 4.61396 14.4872 5 15 5H17.5858L9.29289 13.2929L9.2097 13.3871C8.90468 13.7794 8.93241 14.3466 9.29289 14.7071C9.68342 15.0976 10.3166 15.0976 10.7071 14.7071L19 6.41421V9L19.0067 9.11662C19.0645 9.61396 19.4872 10 20 10C20.5523 10 21 9.55228 21 9V4C21 3.96137 20.9978 3.92274 20.9933 3.88433L20.9933 3.88338C20.9508 3.51768 20.711 3.21216 20.3832 3.07605ZM11 6C11.5523 6 12 6.44772 12 7C12 7.51284 11.614 7.93551 11.1166 7.99327L11 8H6C5.48716 8 5.06449 8.38604 5.00673 8.88338L5 9V18C5 18.5128 5.38604 18.9355 5.88338 18.9933L6 19H15C15.5128 19 15.9355 18.614 15.9933 18.1166L16 18V13C16 12.4477 16.4477 12 17 12C17.5128 12 17.9355 12.386 17.9933 12.8834L18 13V18C18 19.5977 16.7511 20.9037 15.1763 20.9949L15 21H6C4.40232 21 3.09634 19.7511 3.00509 18.1763L3 18V9C3 7.40232 4.24892 6.09634 5.82373 6.00509L6 6H11Z"
    />
  </SvgIcon>
)

export default ExternalLinkIcon