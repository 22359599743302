import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CopyIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} fontSize="inherit" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9949 5.82373C16.9037 4.24892 15.5977 3 14 3H6L5.82373 3.00509C4.24892 3.09634 3 4.40232 3 6V14L3.00509 14.1763C3.09634 15.7511 4.40232 17 6 17H7V18C7 19.6569 8.34315 21 10 21H18C19.6569 21 21 19.6569 21 18V10C21 8.34315 19.6569 7 18 7H17V6L16.9949 5.82373ZM15 7V6C15 5.48716 14.614 5.06449 14.1166 5.00673L14 5H6C5.48716 5 5.06449 5.38604 5.00673 5.88338L5 6V14C5 14.5128 5.38604 14.9355 5.88338 14.9933L6 15H7V10C7 8.34315 8.34315 7 10 7H15ZM9 16V18C9 18.5523 9.44772 19 10 19H18C18.5523 19 19 18.5523 19 18V10C19 9.44772 18.5523 9 18 9H16H10C9.44772 9 9 9.44772 9 10V16Z"
    />
  </SvgIcon>
);

export default CopyIcon;
